<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运营工具</a>
          <i>></i>
          <a href="javascript:;" class="cur-a" @click="$router.back();">实习岗位管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">查看学员</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div class="searchboxItem ci-full">
              <span title="学员信息" class="itemLabel">学员信息:</span>
              <el-select v-model="retrievalData.studentId" placeholder="请选择学员" remote size="small" clearable
                :remote-method="superUserSelect" @visible-change="clearUserSearchModel">
                <template>
                  <div class="select-header">
                    <span style=" color: #8492a6; float: left; font-size: 13px; width: 100px;">学员姓名</span>
                    <span
                      style=" color: #8492a6; float: left; font-size: 13px; margin-left: 50px; width: 140px;">身份证号码</span>
                    <span
                      style=" color: #8492a6; float: left; font-size: 13px; margin-left: 50px; width: 100px;">学员电话</span>
                  </div>
                  <div class="select-header">
                    <el-input v-model="userDatas.studentName" v-on:input="superUserSelect" type="text" size="small"
                      placeholder="学员姓名" clearable style="width: 100px" />
                    <el-input v-model="userDatas.studentIdcard" v-on:input="superUserSelect" type="text" size="small"
                      placeholder="身份证号码" clearable style="margin-left: 50px; width: 140px" />
                    <el-input v-model="userDatas.studentMobile" v-on:input="superUserSelect" type="text" size="small"
                      placeholder="学员电话" clearable style="margin-left: 50px; width: 100px" />
                  </div>
                </template>
                <el-option v-for="(item, index) in userDatas.userList" :key="index" :label="item.studentName"
                  :value="item.studentId">
                  <span style="width: 100px; font-size: 13px">{{ item.studentName }}</span>
                  <span style="width: 140px; margin-left: 50px; font-size: 13px">{{ item.studentIdcard }}</span>
                  <span style="width: 100px; margin-left: 50px; font-size: 13px">{{ item.studentMobile }}</span>
                </el-option>
              </el-select>
            </div>
            <div title="审核状态" class="searchboxItem ci-full">
              <span class="itemLabel">审核状态:</span>
              <el-select v-model="retrievalData.internAuditState" clearable size="small" placeholder="请选择状态">
                <el-option v-for="item in internAuditStateData" :key="item.value" :label="item.label"
                  :value="item.value" />
              </el-select>
            </div>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="getData()">查询</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column prop="departmentName" align="left" label="所在院系" show-overflow-tooltip min-width="150" />
              <el-table-column prop="projectName" align="left" label="班级名称" show-overflow-tooltip min-width="120" />
              <el-table-column prop="studentName" align="left" label="姓名" min-width="80" />
              <el-table-column prop="studentIdcard" align="left" label="身份证号" width="180" />
              <el-table-column prop="studentMobile" align="left" label="手机号" width="120" />
              <el-table-column prop="emergencyContact" align="left" label="紧急联系人姓名" width="130" />
              <el-table-column prop="emergencyContactMobile" align="left" label="紧急联系人电话" width="130" />
              <el-table-column prop="applyTime" align="left" label="报名日期" width="160" />
              <el-table-column prop="internAuditState" align="left" label="状态" width="100">
                <template slot-scope="scope">
                  {{ $setDictionary('INTERN_SIGN_AUDIT_STATE', scope.row.internAuditState) }}
                </template>
              </el-table-column>
              <el-table-column prop="remark" label="备注" min-width="160" />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "workOrder_internshipPositionManagementInfo",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 上个页面传参
      lastPage: {
        batchId: "", // 批次id
      },
      // 检索数据
      retrievalData: {
        studentId: "", // 学员id
        internAuditState: "", // 审核状态
      },
      // 学员检索相关数据
      userDatas: {
        studentName: "",//学员姓名
        studentIdcard: "",//身份证号
        studentMobile: "",//手机号
        userList: [], // 学员下拉数据
      },
      // 审核状态 - 下拉数据
      internAuditStateData: [],
    };
  },
  watch: {},
  created() {
    this.getInvoice_type();
  },
  computed: {},
  mounted() {
    this.lastPage.batchId = this.$route.query?.batchId ?? "";  // 批次id
  },
  methods: {
    // 获取 - 学员信息 - 下拉数据
    superUserSelect(e) {
      this.$post(
        "/biz/project/student/studentListRun",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          studentName: this.userDatas.studentName,
          studentIdcard: this.userDatas.studentIdcard,
          studentMobile: this.userDatas.studentMobile,
        },
        3000,
        false,
        7
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.userDatas.userList = [{}];
        } else {
          this.userDatas.userList = res.data.list;
        }
      });
    },
    // 获取 - 学员信息 - 下拉框出现/隐藏时触发
    clearUserSearchModel(e) {
      if (e) {
        this.userDatas.studentName = "";
        this.userDatas.studentIdcard = "";
        this.userDatas.studentMobile = "";
        this.superUserSelect();
      }
    },
    // 获取 - 获取字典
    getInvoice_type() {
      // 审核结果
      let arr1 = this.$setDictionary("INTERN_SIGN_AUDIT_STATE", "list");
      for (const key in arr1) {
        this.internAuditStateData.push({
          label: arr1[key],
          value: key
        });
      }
    },
    // 获取 - 列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        batchId: this.$route.query.batchId
      };
      if (this.retrievalData.studentId) {
        params.studentId = this.retrievalData.studentId;
      }
      if (this.retrievalData.internAuditState) {
        params.internAuditState = this.retrievalData.internAuditState;
      }
      this.doFetch({
        url: "/biz/batch/intern-batch-user/pageList",
        params,
        pageNum,
      }, true, 7);
    },
  },
};
</script>
<style lang="less" scope>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>